<template>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <a id="backBtn" class="btn p-0 mr-4">
          <i class="fa fa-arrow-left text-light h5 mb-0"></i>
        </a>
        <span class="navbar-brand bg-transparent rounded p-0 text-center mr-0 mr-md-5">
          <span class="h6 font-weight-bold text-truncate">Bahan</span>
        </span>
        <div class="text-nowrap order-md-last">
          <a class="btn px-0 d-inline-block mr-3">
            <i class="fa fa-share-alt text-light"></i>
          </a>
          <a href="./jual-stok-edit.html" class="btn px-0 d-inline-block">
            <i class="fa fa-pencil text-light"></i>
          </a>
        </div>
      </nav>
    </div><!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col pb-5 with-fixed-sidebar">

        <div class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin" style="background-image: url(./img/stock/s1.jpg);">
          <div class="container text-center">
            <p class="h5 my-2 text-white">Mayones 1 kg</p>
          </div>
        </div>
      
        <div class="row d-none">
          <div class="col-12">
            <div class="alert alert-secondary bg-light alert-dismissible fade show" role="alert">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <strong>Alert!</strong> You should <a href="javascript:void(0);" class="alert-link" title="Click to go to this message">read this message</a>.
              <button id="submitButton" class="btn btn-sm btn-light border py-0 mt-n1" type="submit" title="Action..."><i class="fa fa-user mr-1"></i>Action</button>
            </div>
          </div> <!-- .col -->
        </div> <!-- .row -->

        <div class="row mt-3">
          <div class="col-6">
            <p class="mb-1 small text-muted">Perkiraan Sisa Aktif</p>
            <p class="mb-0 text-main">450 gr</p>
          </div><!-- .col -->
          <div class="col-6 text-right text-md-left">
            <p class="mb-1 small text-muted">Satuan Per Pcs</p>
            <p class="mb-0">1000 gr</p>
          </div><!-- .col -->
        </div><!-- .row -->

        <div class="row mt-3">
          <div class="col-6">
            <p class="mb-1 small text-muted">Stok Cadangan</p>
            <p class="mb-0">2 pcs</p>
          </div><!-- .col -->
          <div class="col-6 text-right text-md-left">
            <p class="mb-1 small text-muted">Harga Beli Satuan</p>
            <p class="mb-0 currency">51000</p>
          </div><!-- .col -->
        </div><!-- .row -->

        <div class="row mt-3">
          <div class="col-6">
            <p class="mb-1 small text-muted">Dipakai oleh</p>
            <p class="mb-0">2 produk</p>
          </div><!-- .col -->
          <div class="col-6 text-right text-md-left">
            <p class="mb-1 small text-muted">Supplier</p>
            <p class="mb-0"><a href="">Online Shop</a></p>
          </div><!-- .col -->
        </div><!-- .row -->

        <div class="row mt-3">
          <div class="col-12">
            <p class="mb-1 small text-muted">Deskripsi</p>
            <p class="mb-0">Mayones Maestro 1 kg Pouch</p>
          </div><!-- .col -->
        </div><!-- .row -->

        <div class="row my-3">
          <div class="col-12 px-0">
            <ul class="list-group rounded-0">
              <li class="list-group-item d-flex justify-content-between align-items-center border-left-0 border-right-0 p-0">
                <a href="./jual-stok-riwayat.html" class="py-2 pl-3 text-uppercase stretched-link">Riwayat Pembelian</a>
                <i class="fa fa-caret-right mr-3"></i>
              </li>
            </ul>
          </div><!-- .col -->
        </div><!-- .row -->
  
        <div class="small text-truncate">Produk Yang Menggunakan:</div>

        <div class="row mt-3 mb-5">
          <div class="col-12 col-md-6 col-lg-4 col-xl-3">
            <div class="card mb-2">
              <div class="card-body p-0">
                <div class="float-left w-25 border-right">
                  <div class="cropped" style="background-image:url('./img/prod/p4.jpg')"></div>
                </div>
                <div class="float-right w-75 p-2 pl-3">
                  <a href="" class="card-title text-main font-weight-bold d-block mb-1 mt-1 card-link stretched-link text-truncate">Salmon Mentai</a>
                  <p class="card-text mb-1 text-truncate">Per pcs memakai 50 gr</p>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div><!-- .col -->

          <div class="col-12 col-md-6 col-lg-4 col-xl-3">
            <div class="card mb-2">
              <div class="card-body p-0">
                <div class="float-left w-25 border-right">
                  <div class="cropped" style="background-image:url('./img/prod/p5.jpg')"></div>
                </div>
                <div class="float-right w-75 p-2 pl-3">
                  <a href="" class="card-title text-main font-weight-bold d-block mb-1 mt-1 card-link stretched-link text-truncate">Spaghetti Brulee</a>
                  <p class="card-text mb-1 text-truncate">Per pcs memakai 100 gr</p>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div><!-- .col -->
        </div><!-- .row -->

        <a href="./jual-stok-edit.html" id="btn-float" class="bg-warning position-fixed rounded-circle shadow text-dark text-center"><i class="fa fa-plus h5 mt-3"></i></a>
  
      </div><!-- main-content -->
    </div><!-- .row -->
  </div><!-- .container -->
</template>